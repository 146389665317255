import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import dealerGroupServices from 'services/dealerGroupServices';
import useSnackBar from 'hooks/useSnackBar';

interface Props {
  apiProperty: string;
  selectedValues?: FilterOption[];
}

export default function useDepartments({ apiProperty, selectedValues }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetGroupDepartment() {
    return useCustomMutation({
      key: 'getGroupDepartment',
      handleReturn: {
        onError: () => {
          handleSnackBarError('Ocorreu um erro ao consultar "Departamentos".');
        },
      },
      request: async () =>
        dealerGroupServices
          .getDepartmentsByGrupId(groupId)
          .then(response => response?.data),
    });
  }

  const {
    data,
    isLoading,
    mutate: getGroupDepartment,
  } = useGetGroupDepartment();

  useEffect(() => {
    if (options?.length) {
      if (!selectedValues?.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedValues && selectedValues.length) {
        const filtered = options.filter(option => {
          const selectedValue = selectedValues.some(selected => {
            return selected.value === option.value;
          });
          return selectedValue;
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedValues]);

  useEffect(() => {
    if (Array.isArray(data) && data?.length) {
      const formatted: FilterOption[] = data.map(el => ({
        filterGroupId: apiProperty,
        id: el?.code,
        label: el?.name,
        value: el?.code,
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getGroupDepartment,
    isLoading,
  };
}
