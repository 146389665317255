import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import callCenterServices from 'services/callCenterServices';
import useSnackBar from 'hooks/useSnackBar';

interface Props {
  apiProperty: string;
  selectedsValues?: string[];
}

export default function useTags({ apiProperty, selectedsValues }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetGroupTags() {
    return useCustomMutation({
      key: 'getGroupTags',
      handleReturn: {
        onError: () => {
          handleSnackBarError('Ocorreu um erro ao consultar "Tags".');
        },
      },
      request: async () =>
        callCenterServices
          .getTagsByGrupId(groupId)
          .then(response => response?.data),
    });
  }

  const { data, isLoading, mutate: getGroupTags } = useGetGroupTags();

  useEffect(() => {
    if (options.length) {
      if (!selectedsValues.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedsValues && selectedsValues.length) {
        const filtered = options.filter(option => {
          return selectedsValues.some(selected => {
            return selected === option.value;
          });
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedsValues]);

  useEffect(() => {
    if (Array.isArray(data) && data?.length) {
      const formatted: FilterOption[] = data.map(tagName => ({
        filterGroupId: apiProperty,
        id: `${apiProperty}-${tagName}`,
        label: tagName,
        value: String(tagName),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getGroupTags,
    isLoading,
  };
}
