import React, { Fragment, useState } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';

import { Skeleton } from '@material-ui/lab';
import { CircularProgress } from '@material-ui/core';

import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';

import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';

import { useGenericForm } from './hooks/useGenericForm';
import useGetProposalStatus from './hooks/useGetProposalStatus';
import { DynamicFieldsForm } from './DynamicFieldsForm';

import * as S from './styles';

const LoadingComponent = () => {
  return (
    <>
      <div style={{ padding: '11px 0px' }}>
        <Skeleton width="100%" height="20px" variant="rect" animation="wave" />
      </div>
      <div style={{ padding: '11px 0px' }}>
        <Skeleton width="100%" height="20px" variant="rect" animation="wave" />
      </div>
    </>
  );
};

const ConfirmStatusDialog = ({
  isOpen,
  confirmStatus,
  isCallCenter = false,
  isOem = false,
  draggableItemId,
  departmentId = null,
  onClose,
  clientUserCpfCnpj,
  currentProposalId,
  titleModal = `Mover para "Concluído"`,
}) => {
  const { data: response, isLoading } = useGetProposalStatus({
    draggableItemId,
    isCallCenter,
    departmentId,
    isOem,
    currentProposalId,
  });

  const currentProposalData = Array.isArray(response?.data)
    ? response.data
    : [];

  const [selectedItem, setSelectedItem] = useState('');

  const { formFields, initialFormValues, validationSchema } = useGenericForm({
    statusList: currentProposalData,
    selectedStatus: selectedItem,
  });

  const buildSubmitFormData = async values => {
    const status = selectedItem;

    const reasonInfo = _.isEmpty(values)
      ? undefined
      : values?.statusDetailId?.value;

    await confirmStatus(status, reasonInfo);
  };

  const formik = useFormik({
    initialValues: initialFormValues({
      ...(clientUserCpfCnpj && { orderCpfCnpj: clientUserCpfCnpj }),
    }),
    validationSchema: validationSchema,
    onSubmit: async values => {
      buildSubmitFormData(values);
      onClose();
    },
    enableReinitialize: true,
  });

  const handleConfirmChangeStatus = () => {
    formik.handleSubmit();
  };

  return (
    <GenericDialog
      open={isOpen}
      onClose={onClose}
      title={titleModal}
      maxWidth={'xs'}
      DialogAction={() => (
        <S.LineEnd>
          <ThemeButton
            fullWidth
            onClick={!isLoading && handleConfirmChangeStatus}
            disabled={!selectedItem || isLoading}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={'1.5rem'} thickness={7} />
            ) : (
              'Concluir'
            )}
          </ThemeButton>
        </S.LineEnd>
      )}
    >
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <Fragment>
          {currentProposalData?.map(element => {
            return (
              <S.StatusWrapper key={element.id}>
                <S.StatusButton
                  key={element?.id}
                  onClick={() => setSelectedItem(element.status)}
                >
                  <S.LineSpaceBetween>
                    <S.Label>{element?.label}</S.Label>
                    <RadioButton checked={selectedItem === element?.status} />
                  </S.LineSpaceBetween>
                </S.StatusButton>

                {selectedItem === element?.status && (
                  <DynamicFieldsForm formik={formik} formFields={formFields} />
                )}
              </S.StatusWrapper>
            );
          })}
        </Fragment>
      )}
    </GenericDialog>
  );
};

export default ConfirmStatusDialog;
