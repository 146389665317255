import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import PaginationDefaultOptions from 'constants/PaginationDefaultOptions';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import DealerServices from 'services/dealerServices';
import ProviderServices from 'services/providerServices';
import CallCenterServices from 'services/callCenterServices';
import ProposalServices from 'services/proposalServices';
import InventoryService from 'services/inventoryServices';

import NewCardComponent from './NewCardComponent';
import proposalSchema from './proposalSchema';

//HELPERS
const buildOptions = list => {
  return list.map(item => ({
    label: item.label,
    value: item.id,
  }));
};
const buildDealersList = statusList => {
  const dealersListTemp = [];
  statusList.map(item =>
    dealersListTemp.push({
      label: item.name,
      value: item.id,
      name: item.name,
    })
  );
  return dealersListTemp;
};

function buildOptionsToParams(options) {
  const optionsNew = { ...PaginationDefaultOptions.options };

  optionsNew.orderFilter = !options.orderFilter
    ? PaginationDefaultOptions.options.orderFilter
    : options.orderFilter;

  optionsNew.usado = options.usado
    ? PaginationDefaultOptions.options.usado
    : options.usado;

  optionsNew.zeroKm = options.zeroKm
    ? PaginationDefaultOptions.options.zeroKm
    : options.zeroKm;

  optionsNew.query = !options.query
    ? PaginationDefaultOptions.options.query
    : options.query;

  optionsNew.publishStatus = !options.publishStatus
    ? PaginationDefaultOptions.options.publishStatus
    : options.publishStatus;

  optionsNew.scoreFilter = !options.scoreFilter
    ? PaginationDefaultOptions.options.scoreFilter
    : options.scoreFilter;

  optionsNew.dashFilter = !options.dashFilter
    ? PaginationDefaultOptions.options.dashFilter
    : options.dashFilter;

  optionsNew.planIdFilter = !options.planIdFilter
    ? PaginationDefaultOptions.options.planIdFilter
    : options.planIdFilter;

  optionsNew.showroom = options.showroom;
  optionsNew.booked = options.booked;
  return optionsNew;
}

const NewCardContainer = props => {
  const { email } = useSelector(state => state.userStore);
  const [state, setState] = useState({
    query: '',
    dealsCheckeds: [],
    //LOADINGS
    isLoadingDealers: true,
    isLoadingDeals: false,
    isLoadingCreateProposal: false,
    //OPTIONS
    dealersList: [],
    channelOptions: [],
    departmentOptions: [],
    originOptions: [],
    //DEALS
    deals: [],
  });

  const dispatch = useDispatch();

  const addProposal = async (
    dealerId,
    cpf,
    name,
    email,
    mobile,
    origin,
    vehicles,
    sellerEmail,
    groupId,
    providerId,
    contactId,
    departmentCode,
    callId
  ) => {
    const result = await ProposalServices.createProposal(dealerId, {
      cpf,
      email,
      name,
      mobile,
      dealIds: vehicles,
      origin,
      sellerEmail,
      groupId,
      providerId,
      contactId,
      departmentCode,
      callId,
    });
    const { success, data } = result;

    if (success) {
      if ((data || {}).id) {
        return { success: true, id: (data || {}).id, message: data.message };
      }
      return { success: false, id: null };
    } else {
      return { success: false, id: null };
    }
  };

  const createProposal = async formData => {
    try {
      setState(prevState => ({ ...prevState, isLoadingCreateProposal: true }));
      const { success, id, message } = await addProposal(
        formData.currentDealerSelected,
        formData.cpfCnpj,
        formData.name,
        formData.email,
        formData.mobile,
        formData.originSelected,
        state.dealsCheckeds,
        email,
        props.currentGroupId,
        formData.channelSelected,
        undefined,
        formData.departmentCode
      );

      if (success) {
        props.openProposal({
          dealerId: formData.currentDealerSelected,
          proposalId: id,
        });
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            message || 'Proposta criada com sucesso.'
          )
        );
      } else {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            message || 'Desculpe, ocorreu um erro inesperado.'
          )
        );
      }
    } catch (error) {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, ocorreu um erro inesperado.'
        )
      );
    } finally {
      setState(prevState => ({ ...prevState, isLoadingCreateProposal: false }));
    }
  };

  //FORMIK
  const {
    values: formData,
    handleChange,
    errors: formikErrors,
    touched: formikTouched,
    handleBlur,
    submitForm: handleClickCreateProposal,
    setFieldValue,
  } = useFormik({
    initialValues: proposalSchema.cast(
      props.currentDealer
        ? { currentDealerSelected: Number(props.currentDealer.value) }
        : {}
    ),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: proposalSchema,
    onSubmit: values => createProposal(values),
  });

  const getAllDeals = async (
    page = PaginationDefaultOptions.page,
    size = PaginationDefaultOptions.size,
    options = { ...PaginationDefaultOptions.options },
    dealerId = null
  ) => {
    setState(prevState => ({ ...prevState, deals: [] }));
    const params = buildOptionsToParams(options);
    const result = await InventoryService.getDealsStock({
      dealerId: dealerId || this.rootStore.dealerStore.dealer.id,
      order: 1,
      page,
      size,
      options: params,
    });

    const { data, success } = result;
    if (success) {
      setState(prevState => ({ ...prevState, deals: data }));
    } else {
      setState(prevState => ({ ...prevState, deals: [] }));
    }
  };

  const getDeals = async (query = null) => {
    setState(prevState => ({ ...prevState, isLoadingDeals: true }));

    await getAllDeals(
      PaginationDefaultOptions.page,
      999,
      {
        query,
      },
      formData.currentDealerSelected
    );
    setState(prevState => ({ ...prevState, isLoadingDeals: false }));
  };

  const searchDeals = () => {
    getDeals(state.query);
  };

  const handleKeyPressToSearchDeals = event => {
    if (event.key === 'Enter') {
      searchDeals();
    }
  };

  const getDepartments = async () => {
    try {
      const { success, data } = await DealerServices.getAllDepartamentByDealer(
        formData.currentDealerSelected
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          departmentOptions: data.map(el => ({
            value: el.code,
            label: el.name,
          })),
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDealers = async () => {
    const { currentDealer } = props;
    setState(prevState => ({ ...prevState, isLoadingDealers: true }));
    if (currentDealer) {
      return setState(prevState => ({
        ...prevState,
        dealersList: [currentDealer],
        isLoadingDealers: false,
      }));
    }
    const { data, success } = await CallCenterServices.getDealersByGroup(
      props.currentGroupId
    );
    if (success) {
      const dealersList = buildDealersList(data);
      setState(prevState => ({
        ...prevState,
        dealersList,
        isLoadingDealers: false,
      }));
    }
  };

  const getProviders = async () => {
    const { data, success } = await ProviderServices.getProviderLeadByDealer(
      formData.currentDealerSelected
    );
    if (success) {
      setState(prevState => ({
        ...prevState,
        channelOptions: buildOptions(data),
      }));
    }
  };

  const getOrigins = async () => {
    try {
      const { data, success } = await ProviderServices.getOriginsOptions(
        formData.currentDealerSelected
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          originOptions: buildOptions(data),
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnChangeSearchDeal = event => {
    setState(prevState => ({
      ...prevState,
      query: event.target.value,
    }));
  };

  const handleClickCheckDeal = item => {
    const checkedsId = state.dealsCheckeds;
    if (state.dealsCheckeds.includes(item)) {
      const index = checkedsId.indexOf(item);
      if (index > -1) {
        checkedsId.splice(index, 1);
      }
    } else {
      // add for array checkeds
      checkedsId.push(item);
    }
    setState(prevState => ({
      ...prevState,
      dealsCheckeds: checkedsId,
    }));
  };

  useEffect(() => {
    getDealers();
  }, []);

  const cleanFields = () => {
    setFieldValue('departmentCode', null);
    setFieldValue('originSelected', null);
    setFieldValue('channelSelected', null);
    setState(prevState => ({
      ...prevState,
      channelOptions: [],
      departmentOptions: [],
      originOptions: [],
      dealsCheckeds: [],
    }));
  };

  useEffect(() => {
    //because inital "currentDealerSelected" is undefined and clean button input change to null
    if (formData.currentDealerSelected === null) {
      cleanFields();
    }
    if (formData.currentDealerSelected) {
      cleanFields();
      getDeals();
      getDepartments();
      getProviders();
      getOrigins();
    }
  }, [formData.currentDealerSelected]);

  const viewComponentProps = {
    openProposal: props.openProposal,
    //OPTIONS
    dealersList: state.dealersList,
    originOptions: state.originOptions,
    channelOptions: state.channelOptions,
    departmentOptions: state.departmentOptions,
    //DELAS
    deals: state.deals,
    //LOADINGS
    isLoadingDeals: state.isLoadingDeals,
    isLoadingDealers: state.isLoadingDealers,
    isLoadingCreateProposal: state.isLoadingCreateProposal,
    //FORMIK
    formData,
    handleChange,
    formikErrors,
    formikTouched,
    handleBlur,
    //DEALS_LIST
    query: state.query,
    dealsCheckeds: state.dealsCheckeds,
    handleOnChangeSearchDeal,
    searchDeals,
    handleKeyPressToSearchDeals,
    handleClickCheckDeal,
    //ON_CLICK_CREATE_PROPOSAL
    handleClickCreateProposal,
  };

  return <NewCardComponent {...viewComponentProps} {...props} />;
};

export default NewCardContainer;
